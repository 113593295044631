<template>
  <div>
    <general-table
      :api-url="APIURL"
      :add-component-name="addComponentName"
      :columns="columns"
      guard="countries"
    />
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'cities',
      addType: 'Add Area',
      addComponentName: 'add-friencountryd',
      editComponent: 'edit-country',
      columns: [
        { key: 'id', sortable: true },
        { key: 'name', label: 'Name' },
        { key: 'name_long', label: 'Arabic Name' },
        { key: 'is_active', label: 'Active' },
        // { key: 'actions' },
      ],
    }
  },
}
</script>

<style>

</style>
